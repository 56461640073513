import {
  Container,
  Box,
  VStack,
  Text,
  Link,
  Stack,
  StackDivider,
  useMediaQuery,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

export default function Footer({ minH }: { minH?: string }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const router = useRouter();
  const { t } = useTranslation('landing-page');
  return (
    <Box
      pt={[5, 6, 9, 12]}
      pb={[4]}
      bg={'lpFooterColor'}
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
      position={'relative'}
      // zIndex={"overlay"}
      bottom={0}
      minH={minH}
      w={'full'}
    >
      <Container zIndex={0} maxW={{ base: 'full', md: '5xl', xl: '1690px' }}>
        <VStack zIndex={0} spacing={[0, 5]}>
          <Stack
            direction={['column', 'column', 'row']}
            spacing={2}
            align="center"
          >
            {/* <StackDivider
              display={{ base: 'none', md: 'flex' }}
              alignSelf={'center'}
              w="4px"
              h="26px"
              bgColor="black"
            /> */}
            <Link
              fontSize={['20px', '30px']}
              fontWeight={700}
              color="#0F37A0"
              _hover={{ textDecor: 'underline' }}
              onClick={() => {
                router.push('/faq');
              }}
            >
              {t('banner-footer-duvidas')}
            </Link>
            <StackDivider
              display={{ base: 'none', md: 'flex' }}
              alignSelf={'center'}
              w="4px"
              h="26px"
              bgColor="#0F37A0"
            />
            <Link
              fontSize={['20px', '30px']}
              fontWeight={700}
              color="#0F37A0"
              _hover={{ textDecor: 'underline' }}
              onClick={() => {
                router.push('/terms');
              }}
            >
              {t('banner-footer-politica')}
            </Link>
            {/* <Link
              fontSize={['xs', 'sm']}
              fontWeight={600}
              color="white"
              _hover={{ textDecor: 'underline' }}
              href="/regulations"
            >
              Regulamento
            </Link> */}
          </Stack>
          <Text
            px={['20px', '20%']}
            color="#0F37A0"
            fontSize={['12px', 'sm']}
            textAlign={['start', 'center']}
          >
            <Trans
              i18nKey="landing-page:banner-footer-description"
              components={isMobile?[<span/>]:[<br />]}
            />
          </Text>
          <Link
            href="https://www.maquinadepromos.com.br"
            color="#0F37A0"
            fontSize={['12px', 'sm']}
            fontFamily={'Montserrat'}
            fontWeight={700}
            textAlign="center"
            target="_blank"
          >
            Desenvolvido por Máquina de Promos
          </Link>
        </VStack>
      </Container>
    </Box>
  );
}
