import {
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Icon,
  Center,
  Button,
  StackDivider,
} from '@chakra-ui/react';
import { useSession, signOut } from 'next-auth/react';
import router from 'next/router';
import { FaAward } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { MdLogout, MdOutlineQuiz } from 'react-icons/md';
import { HiOutlineLogout } from 'react-icons/hi';
// import { IoTicketOutline } from 'react-icons/io5';

export default function UserProfile() {
  const { data: session } = useSession();
  return (
    <HStack zIndex={1}>
      {/* <IconButton
    size="lg"
    variant="ghost"
    aria-label="open menu"
    icon={<FiBell />}
  /> */}
      {!session && (
        <Button
          px={2}
          borderWidth="2px"
          borderColor="#0F37A0"
          variant="outline"
          borderRadius="5px"
          _hover={{ bg: 'blue.500' }}
          _active={{ bg: 'blue.500' }}
          leftIcon={<HiOutlineLogout color="#0F37A0" size={22} />}
          colorScheme="blue"
          onClick={() => {
            router.push('/login');
          }}
        >
          <Text
            color="#0F37A0"
            fontFamily={'DM Sans'}
            fontSize="18px"
            fontWeight={600}
          >
            Entrar
          </Text>
        </Button>
      )}
      {session && (
        <Flex alignItems="center">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack spacing="4">
                <Avatar size="md" name={session.user.email} />
                <VStack
                  display={{ base: 'none', xl: 'flex' }}
                  align="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text color="#1D1D1D">{session.user.email}</Text>
                  {/* {session?.user?.phone ?? (
                    <Text color="white" as="i" onClick={() => router.push("/phone")} cursor="pointer" fontSize='sm' >
                      nenhum telefone cadastrado
                    </Text>
                  )} */}
                </VStack>
              </HStack>
            </MenuButton>
            <MenuList
              color="blackAlpha.800"
              bg="white"
              borderColor="orange.200"
            >
              <MenuItem
                onClick={() => {
                  router.push('/account/prizes');
                }}
                icon={<FaAward />}
              >
                {' '}
                Meus Prêmios
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  router.push('/account/luckynumbers');
                }}
                icon={<IoTicketOutline />}
              >
                {' '}
                <Text> Meus Números da Sorte </Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  router.push('/account/points');
                }}
                icon={<IoTicketOutline />}
              >
                {' '}
                <Text> Meus Pontos </Text>
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  router.push('/account/results');
                }}
                icon={<MdOutlineQuiz />}
              >
                {' '}
                Minha participação
              </MenuItem>
              {/* <MenuItem
                onClick={() => { router.push("/phone") }}
                icon={<BsTelephone />}>Cadastrar telefone</MenuItem> */}
              <MenuDivider display={{ base: 'flex', xl: 'none' }} />
              <MenuItem
                display={{ base: 'flex', xl: 'none' }}
                icon={<MdLogout />}
                onClick={() => {
                  signOut();
                }}
              >
                Sair
              </MenuItem>
            </MenuList>
          </Menu>
          <Box>
            <FiChevronDown color="#1D1D1D" />
          </Box>
          <Center height="30px" px={2} display={{ base: 'none', xl: 'flex' }}>
          <StackDivider
              display={{ base: 'none', md: 'flex' }}
              alignSelf={'center'}
              w="1px"
              h="30px"
              bgColor="#1D1D1D"
            />
          </Center>
          <Box display={{ base: 'none', xl: 'flex' }}>
            <Icon
              aria-label="sair"
              cursor="pointer"
              color="#1D1D1D"
              as={HiOutlineLogout}
              w={8}
              h={8}
              onClick={() => {
                signOut();
              }}
            />
          </Box>
        </Flex>
      )}
    </HStack>
  );
}
